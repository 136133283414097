import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"


const ServicesPage = () => {
    return (
        <Layout>
            <SEO title="Services" />
            <h1>Services</h1>
        </Layout>
    )
}

export default ServicesPage
